import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import useKeyPress from '@hyex/utils/hooks/useKeyPress';
import useMutationObservable from '@hyex/utils/hooks/useMutationObservable';
import useOutsideClick from '@hyex/utils/hooks/useOutsideClick';

export { default as useModalDialog } from './hooks';

import ClientOnlyPortal from './ClientOnlyPortal';
import { MODAL_CONTAINER_ID } from './config';
import type * as ModalDialog from './types';

export * as ModalDialog from './types';

export type PropTypes = {
  close: () => void;
  open: ModalDialog.ModalHandler;
  autoClose?: boolean;
  opened: boolean;
  onClose?: () => void;
  classes?: {
    wrapper?: string;
    visible?: string;
    body?: string;
    closeIcon?: string;
    content?: string;
  };
  children?: ReactNode;
  showCloseButton?: boolean;
};

function hideHtmlScroll() {
  document.documentElement.classList.add('no-scroll');
}

function showHtmlScroll() {
  document.documentElement.classList.remove('no-scroll');
}

export const Modal: FC<PropTypes> = ({
  close,
  onClose,
  autoClose,
  opened,
  classes = {},
  children,
  showCloseButton = true,
}) => {
  const [active, setActive] = useState(false);

  const containerRef = useRef<Node>();

  const modalRef = useRef<HTMLDivElement>(null);

  const bodyRef = useRef<HTMLDivElement>(null);

  const onCloseHandler = () => {
    close();

    onClose && onClose();

    showHtmlScroll();
  };

  useEffect(() => {
    setActive(opened);

    if (opened) {
      document.body.style.overflowY = 'hidden';

      hideHtmlScroll();
    } else {
      document.body.style.overflowY = 'unset';

      onCloseHandler();
    }
  }, [opened]);

  useEffect(() => {
    const el = document.querySelector(`#${MODAL_CONTAINER_ID}`);

    if (el) {
      containerRef.current = el;
    }
  }, []);

  const onModalContainerMutation = useCallback(() => {
    setActive(() => {
      return !modalRef?.current?.nextElementSibling;
    });
  }, []);

  useMutationObservable(containerRef.current || null, onModalContainerMutation, {
    childList: true,
  });

  useOutsideClick({
    ref: bodyRef,
    callback: () => {
      onCloseHandler();
    },
    active: opened && active,
  });

  const escKeyPressed = useKeyPress('Escape');

  useEffect(() => {
    if (escKeyPressed && opened && active) {
      onCloseHandler();
    }
  }, [escKeyPressed, close, opened, active, onClose]);

  useEffect(() => {
    let closeTimeout: ReturnType<typeof setTimeout>;

    if (opened && autoClose) {
      closeTimeout = setTimeout(() => {
        onCloseHandler();
      }, 4000);
    }

    return () => clearTimeout(closeTimeout);
  }, [autoClose, opened, close, onClose]);

  return (
    // <ClientOnlyPortal>
    <div
      ref={modalRef}
      className={clsx('modal-wrapper', classes.wrapper, { visible: active })}
      style={{ visibility: active ? 'visible' : 'hidden' }}
    >
      <div ref={bodyRef} className={clsx('modal-body', classes.body)}>
        <div className={clsx(classes.content, 'modal-content')}>
          {showCloseButton && (
            <button
              type="button"
              className={clsx('close')}
              onClick={() => {
                onCloseHandler();
              }}
            />
          )}
          {children}
        </div>
      </div>
    </div>
    // </ClientOnlyPortal>
  );
};

export default Modal;
